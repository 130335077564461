import React, {useEffect} from "react"
import Layout from '@components/layout/layout.jsx'
import DocumentHeadMeta from '@components/utility/SEO.jsx'
import { StaticImage, getImage } from "gatsby-plugin-image"
import { graphql, Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links";

import './styles.scss'
//Components
import SubNav from '@components/subNav/subNav.jsx'
import Hero from '@components/hero/hero.jsx'
import Portrait from '@components/portrait/portrait.jsx'


// markup
const DiagnosisAndTreatmentPage = ({ data }) => {
  const CarlyPortrait = getImage(data.portraitCarly)
  const HeroImage = getImage(data.hero)

  useEffect(() => {
      document.querySelector('body').addEventListener('click', (e) => {
        document.getElementById(e.target?.href?.split("#")[1])?.focus();
        }
      )
  }, []);

  return (
    <Layout addedClass="page--diagnosis-and-treatment">
      <DocumentHeadMeta title='Diagnosis &amp; Treatment | ALL IN&reg; for Lupus Nephritis' description='Learn more about the tests that are commonly used to help diagnose lupus nephritis and find out how lupus nephritis is treated. Work with a healthcare provider to manage your lupus nephritis.' canonicalURL='https://www.allinforlupusnephritis.com/diagnosis-and-treatment' />
      {/* Bind this to Navbar? */}
      <SubNav>
        <AnchorLink to="/diagnosis-and-treatment#Getting-Diagnosed">Getting Diagnosed</AnchorLink>
        <AnchorLink to="/diagnosis-and-treatment#Treating-Lupus-Nephritis">Treating Lupus Nephritis</AnchorLink>
        <AnchorLink to="/diagnosis-and-treatment#Your-Healthcare-Team">Your Healthcare Team</AnchorLink>
      </SubNav>
      <Hero heroImage={HeroImage} imgAlt={""}>
            <h1>Diagnosis and Treatment</h1>
            <p><strong>Find out what to expect—and what you can do—before and after diagnosis.</strong></p>
      </Hero>
      <section tabIndex="-1" id="Getting-Diagnosed" className="bg-white">
        <div className="container container--narrow">
          <h2>Getting Diagnosed</h2>
          <p className="text-left">People with lupus are at high risk for developing lupus nephritis. This makes routine testing vital to uncovering problems that can lead to more serious outcomes, such as kidney failure or cardiovascular complications.</p>
        </div>
      </section>
      <section className="bg-white">
        <div className="container container--narrow container--common-tests">
          <h3>There are 3 tests commonly used to help diagnose lupus nephritis:</h3>
        </div>
        <div className="container">
            <div className="split-block__item split-block__item--align-top">
                <div className="icon"><StaticImage src="../../images/diagnosis-and-treatment/icon-urine-sample.png" className="" quality="100" placeholder="NONE" alt="" width={70} ></StaticImage></div>
                <div className="text">
                  <p><strong className="semi-bold text--purple">Urine tests</strong> are the most frequent tests you'll receive. They're vital to detect any problems with the way your kidneys are functioning. Your physician will collect a urine sample and check for levels of protein (also referred to as proteinuria) and blood (hematuria) in the urine. <strong className="semi-bold text--purple">Your protein levels should stay below 0.5&nbsp;grams per day.</strong></p>
                  <p>It's recommended that people living with lupus get a urine test every 3&nbsp;months, while those with active lupus nephritis are tested as often as monthly.</p>
                </div>
            </div>
            <div className="split-block__item split-block__item--align-top">
                <div className="icon"><StaticImage src="../../images/diagnosis-and-treatment/icon-blood-sample.png" className="" quality="100" placeholder="NONE" alt="" width={70} ></StaticImage></div>
                <div className="text"><p><strong className="semi-bold text--purple">Blood tests</strong> measure creatinine, a waste product that results from the normal breakdown of muscles. The amount of creatinine in your blood is used to <AnchorLink to="/diagnosis-and-treatment#eGFR" className="link--purple">estimate your glomerular filtration rate (eGFR)</AnchorLink>, which is used to determine how well the kidneys are filtering blood.</p></div>
            </div>
            <div className="split-block__item split-block__item--align-top">
                <div className="icon"><StaticImage src="../../images/diagnosis-and-treatment/icon-kidney.png" className="" quality="100" placeholder="NONE" alt="" width={70} ></StaticImage></div>
                <div className="text"><p><strong className="semi-bold text--purple">A kidney biopsy</strong> confirms the diagnosis and determines how much of the kidney is affected. Usually performed as an outpatient procedure in a hospital, a nephrologist or rheumatologist will use the results to categorize the stage of lupus nephritis and help determine treatment options.</p></div>
            </div>
        </div>
      </section>
      <section id="eGFR" className="bg-white">
        <div className="container container--narrow">
          <h2>What is eGFR?</h2>
          <p>You may hear your doctor talk about your estimated glomerular filtration rate (eGFR). But what does that mean?  Your eGFR is used to see how well your kidneys are filtering blood. Your eGFR is determined by the results of your blood creatinine test, age, and gender.</p>
          <p>Your eGFR number represents how much kidney function you have—the lower the number, the less your kidneys are functioning as they normally should. If your eGFR number is 60 or below, talk to your doctor about treatment options.</p>
        </div>
      </section>
      <section className="bg-light-purple">
        <div className="container container--narrow">
          <Portrait name="Carly" description="Diagnosed with lupus nephritis in 2011" imgSrc={CarlyPortrait} imgAlt=""></Portrait>
          <p>"To anyone recently diagnosed — or on the verge of a diagnosis — I'd tell them this is a journey. There is a community here for you, and we're ready to help you along the way."</p>
        </div>
      </section>
      <section className="bg-white">
        <div className="container">
          <StaticImage src="../../images/social-icon.png" className="" quality="90" placeholder="NONE" alt="" width={200}></StaticImage>
          <h2>Find support throughout your journey</h2>
          <p className="less-margin">Being diagnosed with lupus nephritis can feel overwhelming, confusing, or even isolating. But you don't have to face it alone—the ALL IN community is here to help you every step of the way.</p>
          <a data-modal-id='exit-modal' href="https://www.facebook.com/ALLINforLN" className="cta--purple cta--body cta--body--extra-wide">Join the ALL IN community on social media</a>
        </div>
      </section>
      <section tabIndex="-1" id="Treating-Lupus-Nephritis" className="bg-light-blue">
        <div className="container container--treating-ln bg-purple">
          <h2>Treating lupus nephritis</h2>
          <h3>The main goals of treatment are to:</h3>
          <div className="container--treating-ln__row">
            <div className="container--treating-ln__item">
              <StaticImage src="../../images/diagnosis-and-treatment/icon-treating-ln-1.png" className="icon" quality="90" placeholder="NONE" alt="" width={100}></StaticImage>
              <p className="description">Control inflammation and help preserve kidney health</p>
            </div>
            <div className="container--treating-ln__item">
              <StaticImage src="../../images/diagnosis-and-treatment/icon-treating-ln-2.png" className="icon" quality="90" placeholder="NONE" alt="" width={100}></StaticImage>
              <p className="description">Reduce treatment-related side effects</p>
            </div>
            <div className="container--treating-ln__item">
              <StaticImage src="../../images/diagnosis-and-treatment/icon-treating-ln-3.png" className="icon" quality="90" placeholder="NONE" alt="" width={100}></StaticImage>
              <p className="description">Prevent flare-ups </p>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-light-blue">
        <div className="container container--split container--common-treatments">
          <div className="split-item common-treatments__image">
            <StaticImage src="../../images/diagnosis-and-treatment/common-treatments.png" className="" quality="90" placeholder="NONE" alt="" width={659}></StaticImage>
          </div>
          <div className="split-item common-treatments__text">
            <h2>Common treatments for lupus nephritis include:</h2>
            <p><strong>Immunosuppressants:</strong> Used to treat aggressive or more severe lupus nephritis. These are powerful therapies that keep your immune system from damaging your kidneys.</p>
            <p><strong>Steroids:</strong> Used to quickly reduce and control inflammation of the kidneys.</p>
            <p><strong>Blood pressure medications:</strong> Used to lower blood pressure and may also slow the progression of kidney disease.</p>
            <p><strong>Antimalarials:</strong> Often used to treat lupus and has also been shown to be beneficial in the treatment of lupus nephritis.</p>
          </div>
        </div>
      </section>
      <section className="bg-light-purple">
        <div className="container container--narrow">
          <h2>It's important to diagnose and treat lupus nephritis early to help prevent long-term kidney damage.</h2>
          <a href="/about-lupus-nephritis#Signs-of-LN" className="cta--purple cta--body">Learn more</a>
        </div>
      </section>
      <section tabIndex="-1" id="Your-Healthcare-Team" className="bg-white">
        <div className="container container--narrow">
          <h2>Meet your healthcare team</h2>
          <p className="text-left">People living with lupus nephritis often need to visit different healthcare providers for different needs. You may work with more specialists than the ones listed below, but these are some of the most common ones you'll interact with when managing lupus nephritis.</p>
        </div>
      </section>
      <section className="bg-white">
        <div className="container container--your-healthcare-team">
            <div className="split-block__item split-block__item--align-top">
                <div className="icon"><StaticImage src="../../images/diagnosis-and-treatment/icon-rheumatologist.png" className="" quality="100" placeholder="NONE" alt="" width={70} ></StaticImage></div>
                <div className="text">
                  <h3>Rheumatologist:</h3>
                  <p>Specialist who treats many diseases, including those of the joints and muscles, and may diagnose and treat lupus and lupus nephritis.</p>
                </div>
            </div>
            <div className="split-block__item split-block__item--align-top">
                <div className="icon"><StaticImage src="../../images/diagnosis-and-treatment/icon-nephrologist.png" className="" quality="100" placeholder="NONE" alt="" width={70} ></StaticImage></div>
                <div className="text">
                  <h3>Nephrologist:</h3>
                  <p>Specialist who treats many diseases, including those of the kidney, and may diagnose and treat lupus nephritis. Often, the Nephrologist is the doctor who confirms the diagnosis of lupus nephritis through a kidney biopsy.</p>
                </div>
            </div>
            <div className="split-block__item split-block__item--align-top">
                <div className="icon"><StaticImage src="../../images/diagnosis-and-treatment/icon-primary-care.png" className="" quality="100" placeholder="NONE" alt="" width={70} ></StaticImage></div>
                <div className="text">
                  <h3>Primary care provider:</h3>
                  <p>Doctor you see for regular checkups.</p>
                </div>
            </div>
            <div className="split-block__item split-block__item--align-top">
                <div className="icon"><StaticImage src="../../images/diagnosis-and-treatment/icon-psychologist.png" className="" quality="100" placeholder="NONE" alt="" width={70} ></StaticImage></div>
                <div className="text">
                  <h3>Psychologist:</h3>
                  <p>Doctor who provides emotional support and helps you cope with lupus and lupus nephritis.</p>
                </div>
            </div>
            <div className="split-block__item split-block__item--align-top">
                <div className="icon"><StaticImage src="../../images/diagnosis-and-treatment/icon-dietician.png" className="" quality="100" placeholder="NONE" alt="" width={70} ></StaticImage></div>
                <div className="text">
                  <h3>Dietician/Nutritionist:</h3>
                  <p>A licensed nutrition expert who offers guidance and advice on dietary changes and meal planning based on your specific needs.</p>
                </div>
            </div>
        </div>
      </section>
      <section className="bg-light-purple">
        <div className="container container--narrow">
          <h2>All of your healthcare providers have one common goal: making sure you're staying on track to best manage your lupus nephritis.</h2>
        </div>
      </section>
      <section className="bg-white">
        <div className="container container--narrow container--working-with-hc-team">
          <h2>Prepare for your appointments</h2>
          <p>When living with lupus nephritis, it's critical to meet with your doctor often to help prevent further disease progression and long-term complications. One of the best ways to feel in control of your care is by keeping track of things like appointments, symptoms, lab results, and medications. Be sure to attend all your doctor appointments and get your labs done in advance so your doctor has up-to-date results. If you can't see your doctor in person, you can arrange a telehealth appointment.</p>
          <h3>Whether you're meeting in person or online, here are some tips for working with your doctors:</h3>
          <ul className="standard-list">
            <li>Keep track of your medical appointments and hospital visits</li>
            <li>Write down your symptoms as you experience them over time</li>
            <li>Organize any recent results from lab tests</li>
            <li>Bring an updated list of all your medications, including vitamins and supplements—even if they feel unrelated, it's important to share everything you're taking with your doctor</li>
            <li>Have questions ready for your doctor</li>
            <li>Wear loose-fitting clothing in case you need to show any visible symptoms</li>
            <li>Take a friend or family member with you to your appointment for moral support</li>
            <li>Ask any questions you have</li>
          </ul>
        </div>
      </section>
      <section className="bg-light-purple">
        <div className="container container--narrow">
          <h2>If you happen to miss an appointment, be sure to call your doctor's office in a timely manner to reschedule.</h2>
        </div>
      </section>
      <section className="bg-white">
        <div className="container">
          <div className="container--split checklist-row">
            <div className="split-block--50">
              <StaticImage src="../../images/diagnosis-and-treatment/preparing-for-treatment.png" className="split-item__icon" quality="90" placeholder="NONE" alt="" width={459}></StaticImage>
            </div>
            <div className="split-block--50">
              <div className="checklist-row__list">
                <h3 className="title">If you're preparing for a telehealth appointment, you can also:</h3>
                <div className="checklist-row__item">
                  <div className="icon"><StaticImage src="../../images/check-icon.png" loading="eager" className="" quality="100" placeholder="none" alt="" width={32} ></StaticImage></div>
                  <div className="text">
                    <p>Ensure you have a reliable internet connection</p>
                  </div>
                </div>
                <div className="checklist-row__item">
                  <div className="icon"><StaticImage src="../../images/check-icon.png" loading="eager" className="" quality="100" placeholder="none" alt="" width={32} ></StaticImage></div>
                  <div className="text">
                    <p>Use the chat feature within the telehealth platform</p>
                  </div>
                </div>
                <div className="checklist-row__item">
                  <div className="icon"><StaticImage src="../../images/check-icon.png" loading="eager" className="" quality="100" placeholder="none" alt="" width={32} ></StaticImage></div>
                  <div className="text">
                    <p>Complete any requested lab work prior to appointment to discuss with doctor</p>
                  </div>
                </div>
                <div className="checklist-row__item">
                  <div className="icon"><StaticImage src="../../images/check-icon.png" loading="eager" className="" quality="100" placeholder="none" alt="" width={32} ></StaticImage></div>
                  <div className="text">
                    <p>Ask your doctor about checking your vitals ahead of time (temperature, pulse rate, and blood pressure)</p>
                  </div>
                </div>
                <div className="checklist-row__item">
                  <div className="icon"><StaticImage src="../../images/check-icon.png" loading="eager" className="" quality="100" placeholder="none" alt="" width={32} ></StaticImage></div>
                  <div className="text">
                    <p>Keep an eye out for a text message or email from your doctor that includes a link to your session</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-light-purple">
        <div className="container container--narrow">
          <h2>Feel ready for your next appointment.</h2>
          <p className="text--black">This discussion guide includes suggested questions and <br className="desktop-only" />topics to review with your healthcare provider.</p>
          <Link to="/resources" className="cta--purple cta--body">Download guide</Link>
        </div>
      </section>
    </Layout>
  )
}

export default DiagnosisAndTreatmentPage

export const pageQuery = graphql`
  query DiagnosisAndTreatmentQuery {
    portraitCarly: file(relativePath: {eq: "portrait-Carly.png"}) {
      childImageSharp {
        gatsbyImageData(height:240)
      }
    }
    hero: file(relativePath: {eq: "diagnosis-and-treatment/hero-diagnosis-and-treatment.png"}) {
        childImageSharp {
          gatsbyImageData
        }
      }
  }
`
